import * as React from 'react';

import { Icon } from 'semantic-ui-react';

import { colors } from './sidebar/utils/tokens';

export class TableOfContents extends React.Component {
  state = {
    items: [],
  };

  public setItems(items) {
    this.setState({ items: items });
  }

  public render() {
    let color = `rgb(32, 49, 61)`;

    const items = this.state.items.map((item, index) => (
      <div
        key={index}
        style={{
          paddingLeft: `${item.level}em`,
          textOverflow: `ellipsis`,
          overflow: `hidden`,
          whiteSpace: `nowrap`,
        }}
      >
        <a
          css={{
            color: color,
            ':hover': { color: colors.mondaic.orange, fontWeight: 700 },
          }}
          href={item.link}
        >
          {item.text}
        </a>
      </div>
    ));

    return (
      <div style={{ color: color, fontSize: `75%`, fontWeight: 300 }}>
        <Icon style={{ color: color }} name="align right" />
        <span style={{ fontWeight: 600 }}>PAGE CONTENTS</span>
        {items}
      </div>
    );
  }
}
