import gray from 'gray-percentage';

export default {
  // original palette by @SachaG
  // @see https://www.figma.com/file/J6IYJEtdRmwJQOrcZ2DfvxDD/Gatsby
  gatsby: `#663399`, // was #744c9e
  gatsbyDark: `#442266`,
  gatsbyDarker: `#221133`,
  lemon: `#ffdf37`,
  mint: `#73fff7`,
  lilac: `#8c65b3`,
  lavender: `#b190d5`,
  wisteria: `#ccb2e5`,
  // accent color from the "bolder palette" by @ArchieHicklin
  // @see https://github.com/gatsbyjs/gatsby/issues/1173#issuecomment-309415650
  accent: `#ffb238`, // "Mustard",
  success: `#37b635`,
  warning: `#ec1818`,
  accentLight: `#ffeccd`,
  accentDark: `#9e6100`,
  skyLight: `#dcfffd`,
  skyDark: `#0a75c2`,
  gray: {
    dark: gray(8, 270),
    copy: gray(16, 270),
    lightCopy: gray(35, 270),
    calm: gray(46, 270),
    bright: gray(64, 270),
    light: gray(80, 270),
    superLight: gray(93, 270),
    whisper: gray(98, 270),
    border: gray(93, 270),
  },
  white: `#ffffff`,
  // Mondaic's colors.
  mondaic: {
    yellow: `#FFC912`,
    orange: `#E49254`,
    purple: `#C900C5`,
    violet: `#7331BF`,
    blue: `#0058C8`,
    // UI colors.
    ui: {
      // orange.
      bright: `#f5d8c2`,
      light: `#f9e6d8`,
      whisper: `#FCFAFF`,
    },
    darkGrey: '#20313D',
  },
};
