exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-downloader-credits-tsx": () => import("./../../../src/pages/downloader_credits.tsx" /* webpackChunkName: "component---src-pages-downloader-credits-tsx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy_policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-templates-markdown-doc-tsx": () => import("./../../../src/templates/markdown_doc.tsx" /* webpackChunkName: "component---src-templates-markdown-doc-tsx" */),
  "component---src-templates-notebook-tsx": () => import("./../../../src/templates/notebook.tsx" /* webpackChunkName: "component---src-templates-notebook-tsx" */),
  "component---src-templates-python-api-api-tsx": () => import("./../../../src/templates/python_api/api.tsx" /* webpackChunkName: "component---src-templates-python-api-api-tsx" */)
}

