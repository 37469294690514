import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { Item, Label, Search } from 'semantic-ui-react';
import { navigate } from 'gatsby';

// This seems like a good way to go about it.
// The goal is to load the fuse index only once and then keep reusing it.
// I tried using the useEffect React hook but it kept loading the fuse index
// every time the component was loaded.
// More information:
// https://www.gatsbyjs.com/docs/how-to/images-and-media/static-folder/
const fuse_promise = import('/static/fuse_index.json');

const options = {
  // isCaseSensitive: false,
  includeScore: true,
  // shouldSort: true,
  includeMatches: true,
  // findAllMatches: false,
  minMatchCharLength: 2,
  // location: 0,
  threshold: 0.3,
  // distance: 100,
  // useExtendedSearch: false,
  ignoreLocation: true,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ['title', 'classes.name', 'classes.methods', 'functions'],
};

// Actually load the fuse index.
console.log('Loading search index.');
let fuse = undefined;
fuse_promise.then(function (val) {
  fuse = new Fuse(val.default, options);
});

/**
 * Custom result renderer.
 * @param data
 * @returns Layout for a single result.
 */
const resultRenderer = (data) => {
  return (
    <Item style={{ fontSize: '120%' }}>
      <Item.Content>
        <Item.Header style={{ fontSize: '110%' }}>{data.name}</Item.Header>
        {data.descriptions && (
          // Ugly but works for now.
          <Item.Description style={{ minWidth: '33em' }}>
            {data.descriptions}
          </Item.Description>
        )}
      </Item.Content>
    </Item>
  );
};

/**
 * Highlight sections of a string given certain indices.
 *
 * @param str The string to highlight.
 * @param indices Pairs of indices of the string to highlight.
 * @returns
 */
const highlight = (str: string, indices: number[][]) => {
  let output = [];
  let i = 0;
  for (let index_pair of indices) {
    output.push(<span>{str.slice(i, index_pair[0])}</span>);
    output.push(<b>{str.slice(index_pair[0], index_pair[1] + 1)}</b>);
    i = index_pair[1] + 1;
  }
  if (i < str.length) {
    output.push(<span>{str.slice(i, str.length)}</span>);
  }
  return <>{output}</>;
};

/**
 * Search bar for the documentation.
 */
const SearchBar = () => {
  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');

  const handleResultSelect = (e, { result }) => {
    navigate(result.url);
  };

  const handleSearchChange = (e, { value }) => {
    setValue(value);

    // minCharacters is set to two on the semanticu ui Search component.
    if (value.length <= 1) {
      setResults([]);
      return;
    }

    const result = fuse.search(value);

    // Complicated loop over the results to directly highlight the fuse.js
    // matches and still work with our custom logic.
    const searchResults: {
      [key: string]: { name: string; results: { type: string }[] };
    } = {};
    for (let i = 0; i < Math.min(result.length, 5); i++) {
      const matches = result[i].matches;
      // Make a copy because fuse.js internally reuses results.
      let item = { ...result[i].item };
      // name will be displayed, the title will be used as a key in the search
      // result - so it must be unique.
      item.name = result[i].item.title;

      // We need to create a list of class methods to be able to index into
      // them.
      let classIndices = [];
      let j = 0;
      for (let c of item.classes) {
        classIndices.push({
          name: c.name,
          indices: [j, c.methods.length + j - 1],
        });
        j += c.methods.length;
      }

      let descriptions = [];

      // Currently we only have the Python API type so this might become more
      // complicated over time.
      if (item.type === 'Python API') {
        // Always do the title.
        for (let match of matches) {
          // Special handling for the title.
          if (match.key === 'title') {
            item.name = highlight(item.title, match.indices);
          }
        }

        for (let match of matches) {
          if (descriptions.length >= 5) {
            descriptions.push(<div style={{ height: '5px' }}></div>);
            descriptions.push(
              <span style={{ fontSize: '80%' }}>
                Click to see all results ...
              </span>
            );
            break;
          }
          // Classes.
          else if (match.key === 'classes.name') {
            descriptions.push(
              <div key={descriptions.length}>
                <Label
                  size="tiny"
                  color="purple"
                  horizontal
                  style={{ width: '50px' }}
                >
                  class
                </Label>
                {highlight(match.value, match.indices)}
              </div>
            );
          }
          // Functions.
          else if (match.key === 'functions') {
            descriptions.push(
              <div key={descriptions.length}>
                <Label
                  size="tiny"
                  color="blue"
                  horizontal
                  style={{ width: '50px' }}
                >
                  function
                </Label>
                {highlight(match.value, match.indices)}()
              </div>
            );
          }
          // Class methods.
          else if (match.key === 'classes.methods') {
            // Try to find the class name.
            let className = '';
            const classNameCandidates = classIndices.filter(
              (x) =>
                match.refIndex >= x.indices[0] && match.refIndex <= x.indices[1]
            );
            if (classNameCandidates.length == 1) {
              className = classNameCandidates[0].name;
            }

            descriptions.push(
              <div key={descriptions.length}>
                <Label
                  size="tiny"
                  color="orange"
                  horizontal
                  style={{ width: '50px' }}
                >
                  method
                </Label>
                {className}.{highlight(match.value, match.indices)}()
              </div>
            );
          }
        }
      }

      item.descriptions = descriptions;

      if (!searchResults[item.type]) {
        searchResults[item.type] = {
          name: item.type,
          results: [],
        };
      }
      searchResults[item.type].results.push(item);
    }
    if (result.length > 5) {
      searchResults['...'] = {
        name: '',
        results: [
          { name: 'More results available. Please refine the search.' },
        ],
      };
    }

    setResults(searchResults);
  };

  return (
    <Search
      category
      size="mini"
      minCharacters={2}
      results={results}
      value={value}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
    />
  );
};

export default SearchBar;
