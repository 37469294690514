import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import * as yaml from 'js-yaml';

import { Helmet } from 'react-helmet';

import style from '../css/style';

import { Container, Ref, Sticky } from 'semantic-ui-react';
import '../css/semantic/semantic.less';

import { extendSidebarData } from './sidebar/utils/item-list';
import docsSidebar from '../data/doc-links.yaml';

import { PreviousNextButtons } from './previous_next_buttons_doc';

import { TableOfContents } from './table_of_contents';
import { colors } from './sidebar/utils/tokens';

import { Footer } from './footer';
import { Header } from './header';

import {
  sortVersionNumbers,
  getAvailableVersionNumbers,
  versionNumberFromDocPath,
} from '../utils';

import StickResponsiveSidebar from './sidebar/sticky-responsive-sidebar';

export interface LayoutProps {
  children: any;
  pathname: string;
}

interface TutorialDocItem {
  title: string;
  link: string;
  items?: TutorialDocItem[];
}

const Layout = (props: LayoutProps) => {
  const componentStyle = {
    height: '100%',
  };

  componentStyle.paddingTop = style.globalPaddingTop;

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mondaic - Full Waveform Solutions</title>
      </Helmet>

      <Container fluid css={{ height: '100%' }}>
        <Header pathname={props.pathname} />
        <div css={componentStyle}>{props.children}</div>
      </Container>
    </React.Fragment>
  );
};

export default Layout;

/**
 *  Default, non-documentation layout component.
 */
export const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithLayout extends React.Component<
    P & LayoutProps & { location: { pathname: string } }
  > {
    render() {
      return (
        <Layout pathname={this.props.location.pathname}>
          <Container
            css={{
              height: '100%',
              display: 'flex !important',
              flexDirection: 'column',
            }}
          >
            <div
              css={{
                flex: '1 0 auto',
                paddingLeft: '1ex',
                paddingRight: '1ex',
              }}
            >
              <WrappedComponent {...this.props} />
            </div>
            <Footer />
          </Container>
        </Layout>
      );
    }
  };

/**
 *  Default, non-documentation layout component with a fluid container.
 */
export const withLayoutFluidContainer = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithLayout extends React.Component<
    P & LayoutProps & { location: { pathname: string } }
  > {
    render() {
      return (
        <Layout pathname={this.props.location.pathname}>
          <Container fluid>
            <WrappedComponent {...this.props} />
            <Footer />
          </Container>
        </Layout>
      );
    }
  };

/**
 * Default doc layout component.
 */
export const withDocLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithDocLayout extends React.Component<
    P & LayoutProps & { location: { pathname: string } }
  > {
    contextRef = React.createRef();
    // Some variables influencing the size of everything.
    // Not super clean right now but this must be consistent with the
    // breakpoints in the sidebar.
    minCentralPaneWidth = 450;
    maxCentralPaneWidth = 1100;
    navigationWidth = 300;
    sideNavigationWidth = 175;

    mq = {
      hideSideNavigation: `@media (max-width: ${this.minCentralPaneWidth +
        this.navigationWidth +
        this.sideNavigationWidth
        }px)`,
      hideNavigation: `@media (max-width: ${this.minCentralPaneWidth + this.navigationWidth
        }px)`,
      smallest: `@media (max-width: ${this.minCentralPaneWidth})`,
    };

    constructor(props) {
      super(props);
      this.state = {
        toc: React.createRef(),
        currentTocData: [],
      };
    }

    public updateTableOfContents = (toc) => {
      if (this.state.toc.current !== null) {
        this.state.toc.current.setItems(toc);
      } else {
        this.setState({ currentTocData: toc });
      }
    };

    addTutorialsToDocLinks(
      sidebarData: { title: string; items: TutorialDocItem[] }[],
      tutorialTOC: {
        edges: {
          node: { relativePath: string; fields: { fileContent: string } };
        }[];
      },
      tutorials: {
        edges: {
          node: { slug: string };
        }[];
      },
      thisVersion: string
    ) {
      // First read the table of contents for the current version.
      const tocContents = tutorialTOC.edges.filter(
        (i) => i.node.relativePath.split('/')[0] === thisVersion
      )[0].node.fields.fileContent;
      const toc = yaml.load(tocContents);

      // Dynamically fill in the examples.
      const rootExamplesLink = '/examples';
      const examplesGroup = sidebarData[0].items.filter((i) => {
        return i.link === rootExamplesLink;
      })[0];

      const updateLinks = (item) => {
        if (item.link !== undefined) {
          // Adjust all the links. Does not include the version number as that
          // is added at a later stage.
          item.link = `/examples/${item.link}`;
          // Remove .py endings, if any.
          if (item.link.endsWith('.py')) {
            item.link = item.link.slice(0, -3);
          }
        }
        if (item.items !== undefined) {
          // Recurse.
          item.items.map((i) => updateLinks(i));
        }
      };

      for (const t of toc.items) {
        for (const outer of t.items) {
          for (const item of outer.items) {
            updateLinks(item);
          }
        }
      }

      // Just assign them.
      examplesGroup.items = toc.items;

      return sidebarData;
    }

    /**
     * This function uses the doc links given in the `data/doc-links.yaml` file
     * and dynamically adds links for all parts of the Python API.
     *
     * @param data This object contains the relative paths of all JSON files
     *             for the Python API documentation. It comes from a static
     *             graphql query.
     */
    addPythonAPIToDocLinks(
      data: {
        allFile: { edges: { node: { relativePath: string } }[] };
      },
      thisVersion: string
    ) {
      interface TreeNode {
        title: string;
        link: string;
        items?: TreeNode[];
      }

      const rootAPIUrl = '/references';
      const pythonAPIUrl = rootAPIUrl + '/python_api';

      // Ghetto way to get a deep copy.

      const sidebarData: TreeNode[] = JSON.parse(JSON.stringify(docsSidebar));

      let group_index: number;
      let apiIndex: number;

      // Get the root Python API group.
      const apiItems = sidebarData[0].items!.filter(
        (i: TreeNode, idx: number) => {
          if (i.link != rootAPIUrl) {
            return false;
          }
          group_index = idx;
          return true;
        }
      );
      if (apiItems.length != 1) {
        throw new Error('Could not find API doc link group.');
      }

      const pythonAPIItems = apiItems[0].items!.filter(
        (i: TreeNode, idx: number) => {
          if (i.link != pythonAPIUrl) {
            return false;
          }
          apiIndex = idx;
          return true;
        }
      );
      if (pythonAPIItems.length != 1) {
        throw new Error('Could not find Python API doc link group.');
      }

      // Finally create the tree.
      function addToTree(parts: string[], tree: TreeNode, path: string) {
        const link = `${path.split('/')[0]}/${path
          .split('/')
          .slice(1)
          .join('/')}/${parts[0]}`;

        let items: TreeNode[];
        if (tree.items) {
          items = tree.items.filter((i) => {
            return i.link == link;
          });
        } else {
          items = [];
        }

        let item: TreeNode;
        if (items.length == 0) {
          item = {
            title: parts[0],
            link: link,
          };
          if (!tree.items) {
            tree.items = [];
          }
          tree.items.push(item);
        } else {
          item = items[0];
        }

        if (parts.length > 1) {
          addToTree(parts.slice(1), item, link);
        }
      }

      const pythonDocTree: TreeNode = {
        title: 'Python APIs',
        link: pythonAPIUrl,
        items: [],
      };

      data.edges.forEach((n) => {
        const p = n.node.relativePath
          .split('.')
          .slice(0, -1)
          .join('.')
          .split('/')
          .filter((i) => i != 'python_api');
        if (thisVersion !== p[0]) {
          return;
        }
        addToTree(p.slice(1), pythonDocTree, pythonAPIUrl);
      });

      sidebarData[0].items[group_index].items[apiIndex].items =
        pythonDocTree.items;

      return sidebarData;
    }

    public render() {
      return (
        <Layout pathname={this.props.location.pathname}>
          <Ref innerRef={this.contextRef}>
            {/* Flexbox layout to get a nicely centered documentation -
          semantic UI does not really provide that */}
            <div
              css={{
                display: `flex`,
                flexWrap: `nowrap`,
                justifyContent: `center`,
                minHeight: `calc(100vh - 5em)`,
              }}
            >
              <StaticQuery
                query={graphql`
                  {
                    tutorialTOC: allFile(
                      filter: { base: { eq: "table_of_contents.yml" } }
                    ) {
                      edges {
                        node {
                          relativePath
                          fields {
                            fileContent
                          }
                        }
                      }
                    }
                    tutorials: allJupyterNotebook {
                      edges {
                        node {
                          slug
                        }
                      }
                    }
                    pythonAPI: allFile(
                      filter: {
                        extension: { eq: "json" }
                        sourceInstanceName: { in: ["python_api"] }
                        name: { ne: "salvus_flow_schemas" }
                      }
                    ) {
                      edges {
                        node {
                          relativePath
                        }
                      }
                    }
                  }
                `}
                render={(data) => {
                  const docVersions = getAvailableVersionNumbers();
                  let thisVersion = versionNumberFromDocPath(
                    this.props.location.pathname
                  );
                  if (thisVersion === 'LATEST') {
                    thisVersion = docVersions.current;
                  }

                  // Dynamically insert the Python API docs.
                  let sidebarData = this.addPythonAPIToDocLinks(
                    data.pythonAPI,
                    thisVersion
                  );

                  // Do the same with the tutorials and integration tests.
                  sidebarData = this.addTutorialsToDocLinks(
                    sidebarData,
                    data.tutorialTOC,
                    data.tutorials,
                    thisVersion
                  );

                  const itemList = extendSidebarData(sidebarData, thisVersion);

                  return (
                    <>
                      <div
                        css={{
                          width: `${this.navigationWidth}px`,
                          minWidth: `${this.navigationWidth}px`,
                          [this.mq.hideNavigation]: { width: 0, minWidth: 0 },
                          overflowY: `auto`,
                        }}
                      >
                        <StickResponsiveSidebar
                          itemList={itemList.items}
                          title={'Salvus Documentation'}
                          location={this.props.location}
                          key={this.props.location.pathname}
                        />
                      </div>

                      <div
                        css={{
                          minWidth: `150px`,
                          maxWidth: `${this.maxCentralPaneWidth}px`,
                          paddingLeft: `1.5em`,
                          paddingRight: `1.5em`,
                          [this.mq.smallest]: {
                            paddingLeft: `0.5em`,
                            paddingRight: `0.5em`,
                          },
                          overflow: `auto`,
                          flexBasis: `auto`,
                          flexGrow: 1,
                        }}
                      >
                        <div css={{ maxWidth: '900px', margin: '0 auto' }}>
                          <WrappedComponent
                            {...this.props}
                            updateTableOfContentsFct={
                              this.updateTableOfContents
                            }
                          />
                        </div>
                        <PreviousNextButtons
                          pathname={this.props.location.pathname}
                          itemListDocs={itemList}
                          currentVersion={docVersions.current}
                        />

                        <Footer />
                      </div>
                    </>
                  );
                }}
              />

              <div
                css={{
                  width: `${this.sideNavigationWidth * 2}px`,
                  minWidth: `${this.sideNavigationWidth}px`,
                  maxWidth: `${this.sideNavigationWidth * 2}px`,
                  overflow: `auto`,
                  [this.mq.hideSideNavigation]: { display: `none` },
                  borderLeft: `1px solid ${colors.gray.border}`,
                  paddingLeft: `1ex`,
                  paddingRight: `1ex`,
                }}
              >
                {/* The number is a bit fragile for now but I don't know of a better
                way to do it right now. */}
                <Sticky context={this.contextRef} offset={80}>
                  {/* I'm a bit confused here - just passing the toc data here does
            seem to make a difference even though it is not actually used. */}
                  <TableOfContents
                    ref={this.state.toc}
                    initialTocData={this.state.currentTocData}
                  />
                </Sticky>
              </div>
            </div>
          </Ref>
        </Layout>
      );
    }
  };
