const createHash = link => {
  let index = -1;
  if (link) index = link.indexOf(`#`);
  return index >= 0 ? link.substr(index + 1) : false;
};

const patchWithVersion = (item, version) => {
  if (item.link) {
    item.link = `/${version}${item.link}`;
  }
};

const extendItem = (items, parentTitle, version, level) => {
  items.forEach(item => {
    item.hash = createHash(item.link);
    item.parentTitle = parentTitle;
    item.level = level || 1;
    patchWithVersion(item, version);

    if (item.items) extendItem(item.items, item.title, version, item.level + 1);
  });
};

const extendItemList = (itemList, version) => {
  itemList.forEach(section => {
    section.level = 0;
    patchWithVersion(section, version);
    if (section.items) extendItem(section.items, section.title, version);
  });
  return itemList;
};

const extendSidebarData = (item, version) => {
  return {
    title: item[0].title,
    key: item[0].key,
    disableExpandAll: item[0].disableExpandAll,
    disableAccordions: item[0].disableAccordions,
    items: extendItemList(item[0].items, version),
  };
};

export { extendSidebarData };
