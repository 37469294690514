// Make sure these are consistent with whatever is used in the current semantic
// UI theme.
export default {
  // xs: `400px`,
  // sm: `550px`,
  md: `750px`,
  // lg: `1200px`,
  // xl: `1200px`,
  // xxl: `1600px`,
};
