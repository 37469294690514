import * as React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import {
  Container,
  Grid,
  Dropdown,
  Image,
  Menu,
  Segment,
} from 'semantic-ui-react';

import colorLogo from '../images/logos/mondaic_logo_small_no_text.png';

const getGradient = (opacity: number) =>
  'linear-gradient(135deg, ' +
  `rgba(255,201,18,${opacity}) 0%, ` +
  `rgba(228,146,84,${opacity}) 25%, ` +
  `rgba(201,0,197,${opacity}) 50%, ` +
  `rgba(115,49,191,${opacity}) 75%, ` +
  `rgba(0,88,200,${opacity}) 100%)`;

const menuItemCSS = {
  textTransform: 'none !important',
  fontWeight: '400 !important',
  '@media only screen and (max-width: 500px)': { fontSize: `75%` },
  color: 'white !important',
};

const itemToMenuItem = (item, index) => {
  if (item.link) {
    return (
      <Menu.Item
        link
        as={Link}
        to={item.link}
        activeClassName="active"
        partiallyActive={true}
        key={index}
        css={menuItemCSS}
      >
        {item.name}
      </Menu.Item>
    );
  } else {
    return (
      <Dropdown
        direction="left"
        item
        key={index}
        text={item.name}
        css={menuItemCSS}
      >
        <Dropdown.Menu>
          {item.items.map((inner_item, inner_index) => (
            <Dropdown.Item
              as={Link}
              to={inner_item.link}
              activeClassName="active"
              partiallyActive={false}
              key={inner_index}
              text={inner_item.name}
            ></Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export const Header: React.FunctionComponent<{
  pathname: string;
}> = ({ pathname }) => {
  // For the mobile menu.
  const [visible, setVisible] = React.useState(false);

  // Settings for the root path.
  let menuProps = {
    style: {
      backgroundColor: 'rgb(32, 49, 61)',
      borderImageSource: getGradient(1.0),
      borderImageSlice: '1',
      borderBottomWidth: '2px',
    },
    secondary: false,
    fixed: 'top',
    borderless: true,
    inverted: true,
    size: 'large',
  };

  const headerIcon = colorLogo;
  const mobileMenuColor = 'rgb(32, 49, 61)';

  return (
    <React.Fragment>
      <div
        css={{
          '@media print': {
            display: `none`,
          },
        }}
      >
        <Grid
          css={{
            marginRight: '0px !important',
          }}
          columns={1}
        >
          <Grid.Column css={{ paddingRight: '0px !important' }} only="computer">
            <Menu {...menuProps}>
              <Container>
                <Menu.Item
                  style={{ padding: '0px 1em' }}
                  as={Link}
                  to="/"
                  header
                >
                  <Image src={headerIcon} avatar />
                  Mondaic Documentation
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item as={Link} to="https://www.mondaic.com">
                    www.mondaic.com
                  </Menu.Item>

                </Menu.Menu>
              </Container>
            </Menu>
          </Grid.Column>
          <Grid.Column
            css={{ paddingRight: '0px !important' }}
            only="tablet mobile"
          >
            <Menu {...menuProps}>
              <Container>
                <Menu.Item
                  style={{ padding: '0px 1em' }}
                  as={Link}
                  to="/"
                  header
                >
                  <Image src={headerIcon} avatar />
                  Mondaic
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item css={{ padding: '0px !important' }}>
                    <div
                      css={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'all .5s ease-in-out',
                        cursor: 'pointer',
                      }}
                      onClick={() => setVisible(!visible)}
                    >
                      {/* Funky animated hamburger icon. */}
                      <div
                        css={{
                          width: '22px',
                          height: '4px',
                          borderRadius: '4px',
                          background: visible
                            ? 'transparent'
                            : 'rgba(255, 255, 255, 0.8)',
                          transition: 'all 0.5s ease-in-out',
                          transform: visible ? 'translateX(-20px)' : undefined,
                          '&:before, &:after': {
                            content: "''",
                            position: 'absolute',
                            width: '22px',
                            height: '4px',
                            background: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '4px',
                            transition: 'all 0.5s ease-in-out',
                          },
                          '&:before': {
                            transform: visible
                              ? 'translateX(20px) rotate(45deg)'
                              : 'translateY(-7px)',
                          },
                          '&:after': {
                            transform: visible
                              ? 'translateX(20px) rotate(-45deg)'
                              : 'translateY(7px)',
                          },
                        }}
                      ></div>
                    </div>
                  </Menu.Item>
                </Menu.Menu>
              </Container>
            </Menu>
            {visible && (
              <Menu
                as={Segment}
                borderless
                inverted
                vertical
                size="massive"
                style={{
                  width: '100%',
                  top: '50px',
                  position: 'fixed',
                  backgroundColor: mobileMenuColor,
                  borderImageSource: getGradient(1.0),
                  borderImageSlice: '1',
                  borderBottomWidth: '2px',
                  zIndex: '200',
                }}
              >
                <Container>
                  <Menu.Menu>{menuItems}</Menu.Menu>
                </Container>
              </Menu>
            )}
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};
